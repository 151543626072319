<template>
  <div
    class="dropdown"
    :menu-align="align"
    :opened="opened"
    :behavior="behavior"
    @mouseenter="opened = behavior === 'menu' ? true : opened"
    @mouseleave="opened = behavior === 'menu' ? false : opened"
    @click.stop
  >
    <slot>
      <a class="dropdown-trigger" @click="toggle">
        {{selectedOption ? selectedOption.label : placeholder}}
        <Icon icon="chevron-down" type="fas" size="xs" />
      </a>
    </slot>
    <div class="dropdown-menu">
      <a
        class="dropdown-option"
        v-for="option in options"
        @click="selectOption(option)"
        :selected="value === option.value"
        :key="option.value"
      >
        {{option.label}}
      </a>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
  name: "Dropdown",
  components: {
    Icon,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    behavior: {
      type: String,
      default: 'select',
    },
    align: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    selectedOption() {
      return this.options.find(option => option.value === this.value);
    },
  },
  data() {
    return {
      opened: false,
      touch: "ontouchstart" in document.documentElement,
    };
  },
  methods: {
    selectOption(option) {
      this.$emit('change', option);
      this.close();
    },
    toggle() {
      const opened = !this.opened;
      if (opened) {
        window.dispatchEvent(new Event(this.touch ? 'touchend' : 'click'));
      }
      this.opened = opened;
    },
    close() {
      this.opened = false;
    },
  },
  mounted() {
    if (this.touch || this.behavior !== 'menu') {
      window.addEventListener(this.touch ? 'touchend' : 'click', this.close);
      if (this.touch) {
        this.$el.addEventListener('touchend', e => e.stopPropagation());
      }
    }
  },
  beforeDestroy() {
    if (this.touch || this.behavior !== 'menu') {
      window.removeEventListener(this.touch ? 'touchend' : 'click', this.close);
    }
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
}

.dropdown-trigger {
  display: flex;
  align-items: center;
  color: #0A7E8C;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 260px;
  z-index: 100;
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: opacity .2s ease-in;
}

.dropdown[menu-align="right"] .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown[opened] .dropdown-menu {
  opacity: 1;
  pointer-events: all;
}

.dropdown-menu a {
  display: block;
  padding: 8px 16px;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
  color: #343434;
}

.dropdown[behavior="menu"] .dropdown-menu a,
.dropdown .dropdown-menu a[selected] {
  color: #0A7E8C;
}

.dropdown-menu a:hover {
  background: rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 520px) {
  .dropdown-menu {
    width: 180px;
  }
  .dropdown-menu a {
    font-size: 0.875rem;
  }
}
</style>
